import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ModalImage from "react-modal-image";
import { domainName } from "../../../apis/serverApi";
import { getprojects } from "../../../actions/projectAction";

const Projects = ({ reff }) => {
  const projects = useSelector((state) => state.projects);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getprojects());
  }, [dispatch]);

  // State for filtering
  const [selectedTechnologies, setSelectedTechnologies] = useState([]);
  const [showMore, setShowMore] = useState(false);

  // Function to handle technology selection
  const handleTechnologySelect = (technology) => {
    setSelectedTechnologies((prevTechnologies) => {
      if (prevTechnologies.includes(technology)) {
        return prevTechnologies.filter((tech) => tech !== technology);
      } else {
        return [...prevTechnologies, technology];
      }
    });
    setShowMore(false); // Reset showMore state when technologies are selected
  };

  // Function to check if a project should be displayed based on selected technologies
  const shouldDisplayProject = (proj) => {
    if (!selectedTechnologies.length) {
      return true; // Show all projects if no technologies are selected
    }
    return selectedTechnologies.some((tech) => proj.technologies.includes(tech));
  };

  // Sort projects by creation date (assuming 'createdAt' exists)
  const sortedProjects = projects.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  // Filter projects based on selected technologies and slice based on showMore state
  const displayedProjects = sortedProjects
    .filter(shouldDisplayProject)
    .slice(0, showMore ? sortedProjects.length : 6)
    .map((proj) => (
      <div
        key={proj._id}
        className="col-lg-4 col-12 mb-lg-0 mb-4 mt-lg-5 py-3 shadow-lg bg-white rounded"
      >
        <div className="view overlay rounded z-depth-1">
          <ModalImage
            small={domainName + proj.projectImage}
            large={domainName + proj.projectImage}
            alt={proj.title}
          />
        </div>
        <div className="card-body pb-0">
          <h4 className="font-weight-bold ">{proj.title}</h4>
          <p className="grey-text">{proj.description}</p>
          <p
            className="fw-bold"
            style={{ fontSize: "14px", color: "#55107E", display: "inline" }}
          >
            Technologies:
          </p>
          <p style={{ fontSize: "12px" }}> {proj.technologies}</p>
          {proj.haveLink && (
            <a
              className="btn btn-primary btn-sm shadow-none mb-0"
              target="_blank"
              rel="noreferrer"
              href={proj.link}
            >
              <i className="fa fa-clone left"></i> View Project Code
            </a>
          )}
        </div>
      </div>
    ));

  // Get all unique technologies for filtering checkboxes
  const allTechnologies = [...new Set(projects.flatMap((proj) => proj.technologies))];

  // Display technology filter checkboxes
  const technologyCheckboxes = allTechnologies.map((tech) => (
    <div key={tech} className="form-check form-check-inline">
      <input
        className="form-check-input"
        type="checkbox"
        id={tech}
        checked={selectedTechnologies.includes(tech)}
        onChange={() => handleTechnologySelect(tech)}
      />
      <label className="form-check-label" htmlFor={tech}>
        {tech}
      </label>
    </div>
  ));

  // Determine if the "Show More" button should be displayed
  const showMoreButton =
    sortedProjects.filter(shouldDisplayProject).length > 6 && (
      <button
        className="btn btn-primary mb-4"
        onClick={() => setShowMore(!showMore)}
      >
        {showMore ? "Show Less" : "Show More"}
      </button>
    );

  return (
    <section
      id="projects"
      ref={reff}
      className="text-center py-5"
      style={{ backgroundColor: "#fff" }}
    >
      <div className="container">
        <h2 className="h1-responsive font-weight-bold mb-5">Projects</h2>

        {/* Display technology filter checkboxes */}
        <div className="mb-4">{technologyCheckboxes}</div>

        {/* Display projects */}
        <div className="row text-center d-inline-flex">{displayedProjects}</div>

        {/* Show the "Show More" button if needed */}
        {showMoreButton}
      </div>
    </section>
  );
};

export default Projects;
