import React, { useState, useEffect } from "react";

const Typewriter = ({ words, typingSpeed = 100, delay = 2000 }) => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentText, setCurrentText] = useState("");
  const [isClearing, setIsClearing] = useState(false);
  const currentWord = words[currentWordIndex % words.length];
  const [currentCharIndex, setCurrentCharIndex] = useState(0);

  useEffect(() => {
    let typingTimer;
    let clearingTimer;

    if (!isClearing) {
      // Clear the text after a delay
      clearingTimer = setTimeout(() => {
        setIsClearing(true);
      }, delay);
    } else {
      // Remove one letter at a time
      if (currentCharIndex > 0) {
        typingTimer = setTimeout(() => {
          setCurrentText((prevText) => prevText.slice(0, -1));
          setCurrentCharIndex((prevIndex) => prevIndex - 1);
        }, typingSpeed);
      } else {
        setIsClearing(false);
        setCurrentWordIndex((prevIndex) => prevIndex + 1);
      }
    }

    return () => {
      clearTimeout(typingTimer);
      clearTimeout(clearingTimer);
    };
  }, [currentWord, currentWordIndex, currentCharIndex, isClearing, delay, typingSpeed]);

  useEffect(() => {
    if (!isClearing && currentCharIndex < currentWord.length) {
      const typingTimer = setTimeout(() => {
        setCurrentText((prevText) => prevText + currentWord[currentCharIndex]);
        setCurrentCharIndex((prevIndex) => prevIndex + 1);
      }, typingSpeed);

      return () => clearTimeout(typingTimer);
    }
  }, [currentWord, currentCharIndex, isClearing, typingSpeed]);

  return <span>{currentText}</span>;
};

export default Typewriter;
