import React from "react";

const NotFound = () => {
  return (
    <div className="container d-flex vh-100">
      <h1 className="text-danger mx-auto my-auto">Error 404 :{" "}Page Not Found</h1>
    </div>
  );
};

export default NotFound;
